.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(135deg, #f9fbff 30%, #eef4ff 70%);
    padding: 0 20px;
    overflow: hidden; /* Prevent scrolling */
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .SectionOne {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .getStartedButton {
    background-color: #e63946;
    color: white;
    font-size: 16px;
    padding: 8px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    max-width: 50% ;
    transition: background-color 0.3s ease;
  }
  
  .getStartedButton:hover {
    background-color: #d62839;
  }
  
  .SectionTwo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .rightImage {
    width: 100%;
    max-width: 30%;
  }
  
  .copyright {
    font-size: 14px;
    color: #aaa;
    text-align: center;
    padding-bottom: 10px;
  }
  
  /* Responsive design for small screens */
  @media (max-width: 768px) {
    .container {
      padding: 10px;
      overflow-y: auto;
  
    }
    .content {
      flex-direction: column; /* Stack the sections vertically */
      justify-content: center;
      align-items: center;
      height: auto; /* Allow content to grow */
    }
  
    .title {
      font-size: 28px; /* Adjust font size for smaller screens */
    }
  
    .subtitle {
      font-size: 16px;
    }
  
    .logo {
      margin-bottom: 15px;
    }
  
    .getStartedButton {
      font-size: 16px; /* Adjust button size */
      padding: 10px 20px;
    }
  }
  